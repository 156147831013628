/* eslint-disable quotes */
export default [
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header terms-of-use-container-slide__header--no-margin">Welcome to Livingston Research Platform Terms of Use</div>' +
  '<div class="terms-of-use-container-slide__title">We are truly excited to have you aboard!</div>' +
  '<div class="terms-of-use-container-slide__main-text mb12">Livingston Research Platform (LR Platform) is a <strong>global edtech company</strong>, providing personal education support enabled by technology <br /> to every student.</div>' +
  '<div class="terms-of-use-container-slide__main-text mb12">Our <strong>mission</strong> is to ensure that everyone can get personal study help in any subject 24/7 at the shortest possible notice.</div>' +
  '<div class="terms-of-use-container-slide__main-text">Below we have listed important <strong>terms of use</strong> that apply to anyone who registers at LR Platform. By accepting these terms of use you approve that you have read, understood, and agree to be bound by these Terms.</div>' +
  '</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Premises</div>' +
  '<ul class="terms-of-use-container-slide__list">' +
  '<li class="terms-of-use-container-slide__main-text">LR platform suggests but is not limited to a variety of projects, including academic essays, analytical and research papers, problems, calculations, tests, presentations etc.</li>' +
  '<li class="terms-of-use-container-slide__main-text">All projects are available in different subjects: humanities, social sciences, natural sciences, formal sciences, business, IT. Platform user (freelancer) can choose any, based on their field of expertise and personal preferences.</li>' +
  '<li class="terms-of-use-container-slide__main-text">LR platform owns the copyright for all projects submitted by our freelancers. Therefore, freelancers are not allowed to publish,<br /> resell, or post online any original tasks provided for Livingston Research. All the information regarding projects and clients is kept in complete confidentiality.</li>' +
  '<li class="terms-of-use-container-slide__main-text">Platform user (freelancer) must register under the real name, provide valid contact details and keep them up-to-date. Working <br /> under multiple or fake acсounts (credentials) is strictly prohibited and will lead to all associated accounts being banned.</li>' +
  '<li class="terms-of-use-container-slide__main-text">It is required to fill in a public profile in accordance with the LR platform Public Profile Policies before accepting any projects.</li>' +
  `<li class="terms-of-use-container-slide__main-text">Freelancer must follow all the customer's instructions and guidelines when working on projects, as well as comply with the LR Platform standards and requirements for completed projects.</li>` +
  '<li class="terms-of-use-container-slide__main-text">Freelancer should be ready to revise any completed project (if needed) within 30 days after completion according to the clients’ requests without extra charges.</li>' +
  '</ul>' +
  '</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Working Procedures</div>' +
  '<ul class="terms-of-use-container-slide__list">' +
  '<li class="dbl-margin">Accepting Projects</li>' +
  '<li class="dbl-margin">Quality Requirements</li>' +
  '<li class="dbl-margin">Issue Resolution</li>' +
  '<li class="dbl-margin">Withdrawing Funds</li>' +
  '<li class="dbl-margin">Termination of Cooperation</li>' +
  '<li class="dbl-margin">Contact Information</li>' +
  '</ul>' +
  '</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Accepting Projects</div>' +
  '<ul class="terms-of-use-container-slide__list">' +
  '<li class="terms-of-use-container-slide__main-text mid-margin">As a freelancer you can accept any project available in the projects list. It is your responsibility to select and accept orders for <br /> yourself.</li>' +
  '<li class="terms-of-use-container-slide__main-text mid-margin">However, do not accept projects with instructions identical to projects that you have in work or already completed.</li>' +
  '<li class="terms-of-use-container-slide__main-text mid-margin">Projects are completed automatically after the required files are submitted and all preliminary checks are done. You can ask the Support Team to complete the order manually if it it 24 hours past the deadline and the project is still not completed.</li>' +
  '<li class="terms-of-use-container-slide__main-text mid-margin">You should check your account frequently to find more projects of your interest. You can reserve one project at a time for up to 7 minutes to view all the details and decide whether you are able to complete it. Please mind that you will not be able to reserve the same order twice.</li>' +
  '<li class="terms-of-use-container-slide__main-text mid-margin">By accepting a project, you confirm being able to complete it according to the provided requirements and within the stated deadline.</li>' +
  '</ul>' +
  '</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Quality Requirements</div>' +
  '<div class="row column_gap_24">' +
  '<div>' +
  '<strong class="terms-of-use-container-slide__main-text mb8">No Plagiarism</strong>' +
  '<div class="terms-of-use-container-slide__main-text terms-of-use-container-slide__min-460 mb12">Our company has <strong>zero tolerance</strong> any cases of plagiarism such as:</div>' +
  '<ul class="terms-of-use-container-slide__list">' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Directly copied and not (or improperly) referenced works of other authors</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Paraphrased and not referenced ideas/thoughts of other authors</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">References to nonexistent sources or fake references to real sources</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Anything directly copied from your own projects submitted at LR before</li>' +
  '</ul>' +
  '</div>' +
  '<div>' +
  '<strong class="terms-of-use-container-slide__main-text mb8">Follow Client Requirements</strong>' +
  '<div class="terms-of-use-container-slide__main-text mb12">Project should always <strong>address all of the points specified</strong> in project description/instructions</div>' +
  '<ul class="terms-of-use-container-slide__list">' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Use appropriate language and format (if specified)</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Incorporate peer-reviewed sources (if sources are required in the instructions)</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Mind the volume of the project (+- 5% of project volume are tolerated)</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Finish the project within the stated deadline</li>' +
  '</ul>' +
  '</div>' +
  '</div>' +
  '<div class="row flex-end terms-of-use-container-slide__main-text mb12"><span>If you have any questions about project requirements, contact the <br />Support Team at <a href="mailto:support@liv.research.com">support@liv.research.com</a></span></div>' +
  '<div class="terms-of-use-container-slide__main-text">The details of our quality standards are available in the Scoring Rubric. All freelancers have to study the Scoring Rubric before accepting the first project.</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Issue Resolution</div>' +
  `<div class="terms-of-use-container-slide__main-text mb12">You can report different problems you've encountered at any point of your work on the platform, from missing files or unclear project instructions to questions about feedback received for any completed project.</div>` +
  '<ul class="terms-of-use-container-slide__list">' +
  '<strong class="terms-of-use-container-slide__main-text mb8 block">Order-related Issues</strong>' +
  '<li class="terms-of-use-container-slide__main-text mid-margin">You can report issues connected to any of the projects you have or have not yet worked on, regardless of their status using the <br /> “Report an issue” button in your Issue Resolution tab.</li>' +
  '<strong class="terms-of-use-container-slide__main-text mb8 blockт">Non-order related Issues</strong>' +
  '<li class="terms-of-use-container-slide__main-text mid-margin"><span>Using the same tab you can also report various <strong>project unrelated issues</strong>, included but not limited to inconvenient platform interface, financial issues, communication with Support, etc.</span></li>' +
  '<strong class="terms-of-use-container-slide__main-text mb8 block">Issue Resolution Information</strong>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Depending on the issue urgency and support capacity it usually takes from a couple of minutes to 3 business days to review your case.</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Once the issue is resolved, we will let you know about it by sending a system notification.</li>' +
  '<li class="terms-of-use-container-slide__main-text mid-margin">The full history of your requests, as well as their resolutions is stored in the same Issue Resolution tab.</li>' +
  '</ul>' +
  '</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Identity Verification</div>' +
  '<div class="terms-of-use-container-slide__main-text mb12">We gather your ID’s information and residential address to secure all your financial withdrawals on the platform. You will be able to withdraw funds only after your account is fully verified. Without verification, you will be able to complete only up to 3 orders, then your ability to accept orders into work will be restricted.</div>' +
  `<div class="terms-of-use-container-slide__main-text mb12">The verification process takes <strong>up to 5 minutes.</strong> You can initiate it at any moment by clicking the <strong>"Verify me!"</strong> button in the Freelancer's cabinet header. You will need a smartphone with a working camera and any government-issued ID.</div>` +
  `<div class="terms-of-use-container-slide__main-text mb12">Identity verification and secure data storage are enabled via an independent software tool by <strong>Veriff™.</strong> We, as a platform, do not store any of this data.</div>` +
  `<div class="terms-of-use-container-slide__main-text">Your address is censored in your public profile and can be visible only to you.</div>` +
  '</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Payments</div>' +
  '<ul class="terms-of-use-container-slide__list">' +
  '<strong class="terms-of-use-container-slide__main-text mb8 block">General Terms</strong>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">The compensation for all successfully completed projects is calculated and paid in US dollars.</li>' +
  '<li class="terms-of-use-container-slide__main-text mid-margin">All payments for projects, bonuses and fines are reflected in Finances tab in your cabinet. </li>' +
  '<strong class="terms-of-use-container-slide__main-text mb8 block">Withdrawal Dates</strong>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Money becomes available for withdrawal 14 days after order completion.</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">You can withdraw funds from your available balance twice a month (by the 10th and the 25th day).</li>' +
  '<li class="terms-of-use-container-slide__main-text mid-margin">The compensation is be paid out within 10 days after the withdrawal request deadline. If you request a withdrawal more than once during one payment period, your last withdrawal request will be processed.</li>' +
  '<strong class="terms-of-use-container-slide__main-text mb8 block">Payment Options</strong>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin"><span>Our system allows you to indicate multiple withdrawal methods at a time: <strong>Payoneer, Wise, WireTransfer</strong> (in Help tab there are guides that will save you time for setting up any payment method you choose).</span></li>' +
  '<li class="terms-of-use-container-slide__main-text">We expect you to have at least 2 verified payment methods in your freelancer account at all times.</li>' +
  '</ul>' +
  '</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Two-Factor Authentication (2FA)</div>' +
  '<ul class="terms-of-use-container-slide__list mb20">' +
  '<strong class="terms-of-use-container-slide__main-text mb8 block">Security Enhancement</strong>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">To add an extra layer of security to Freelancers\' accounts, we ask all users to pass obligatory Two - Factor Authentication(2FA) before performing actions with their funds.</li>' +
  '</ul>' +
  '<ul class="terms-of-use-container-slide__list">' +
  '<strong class="terms-of-use-container-slide__main-text mb8 block">How It Works</strong>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">To set up 2FA, you need to download an authenticator app on your mobile device and scan the QR code provided to connect your Livingston Research account.</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Once 2FA is set up, you will be required to enter a one-time code as shown in your authenticator app of choice each time you wish to request the funds withdrawal.</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Any app supporting Time-based One-time Password  (TOTP) can be used, however, we recommend using Google Authenticator.</li>' +
  '</ul>' +
  '</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Termination of Cooperation</div>' +
  `<div class="terms-of-use-container-slide__main-text mb12">LR Platform reserves a right to terminate cooperation and disable freelancer’s account (by notifying the Freelancer via email or phone) in the following cases:</div>` +
  '<ul class="terms-of-use-container-slide__list">' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Disclosure of any information included but not limited to selling/donating/sharing project(s) or project instructions with any third parties.</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Using several user names or fake credentials.</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Contacting Client directly.</li>' +
  `<li class="terms-of-use-container-slide__main-text mid-margin">Systematic violation of Freelancer's premises and LR platform standards and requirements (e.g. quality issues).</li>` +
  '</ul>' +
  `<div class="terms-of-use-container-slide__main-text mt12">The freelancer reserves a right to terminate cooperation by notifying Care Manager via email (<a href="mailto:freelancer.care@liv-research.com">freelancer.care@liv-research.com</a>) at any time if they do not have any pending orders.</div>` +
  '</div>',
  '<div class="terms-of-use-container-slide">' +
  '<div class="terms-of-use-container-slide__header">Contact Information</div>' +
  '<ul class="terms-of-use-container-slide__list">' +
  '<strong class="terms-of-use-container-slide__main-text mb8 block">Order-related questions</strong>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Support Team <br/> (available 24/7)</li>' +
  '<li class="terms-of-use-container-slide__main-text mb20"><a href="mailto:support@liv-research.com">support@liv-research.com</a></li>' +
  '<strong class="terms-of-use-container-slide__main-text mb8 block">Job-related questions</strong>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin">Freelancer Care team <br/> (available business hours GMT+2 Monday-Friday)</li>' +
  '<li class="terms-of-use-container-slide__main-text sm-margin"><a href="mailto:freelancer.care@liv-research.com">freelancer.care@liv-research.com</a></li>' +
  '</ul>' +
  '</div>',
];
