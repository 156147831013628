const uploadFetchAmazon = (fileId, file, onProgress, xhr) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('key', fileId);
        formData.append('AWSAccessKeyId', 'AKIAQ2ZTVN6TAR4CZ7CV');
        formData.append('acl', 'public-read');
        formData.append('policy', 'CnsgImV4cGlyYXRpb24iOiAiMjAzMy0xMi0wMVQxMjowMDowMC4wMDBaIiwKICAiY29uZGl0aW9ucyI6IFsKICAgIHsiYnVja2V0IjogImcweXUwbDRweGoiIH0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRrZXkiLCAid3Q1MDJmeTAvIl0sCiAgICB7ImFjbCI6ICJwdWJsaWMtcmVhZCIgfQogIF0KIH0K');
        formData.append('signature', 'wNkbL+0UdLoq0/IGlTcdJK4vqGw=');
        formData.append('file', file);

        if (!xhr) {
            xhr = new XMLHttpRequest();
        }
        const host = 'https://g0yu0l4pxj.s3.amazonaws.com/';
        xhr.open('POST', host);
        xhr.upload.addEventListener('progress', e => {
            if (e.lengthComputable) {
                const percent = Math.round(100 * e.loaded / e.total);
                onProgress(percent);
            }
        });
        xhr.addEventListener('load', () => resolve(host + fileId));
        xhr.addEventListener('error', () => reject());
        // throw new Error('test');
        xhr.send(formData);
    });
};

export default uploadFetchAmazon;
