const MAX_PLAG_PERCENT = 10;

const FILES_SUB_CATEGORIES = [
  {
    name: 'Instructions',
    id: 'instructions',
  },
  {
    name: 'Sources',
    id: 'sources',
  },
  {
    name: 'Grading rubric',
    id: 'grading_rubric',
  },
  {
    name: 'Readings',
    id: 'readings',
  },
  {
    name: 'Additional materials',
    id: 'additional_materials',
  },
];

const FILES_DUPLICATE_REVISION_SUB_CATEGORIES = [
  {
    name: 'Final files to be revised',
    id: 'final_files_revised',
  },
  ...FILES_SUB_CATEGORIES,
];

export default {
  MAX_PLAG_PERCENT,
  FILES_SUB_CATEGORIES,
  FILES_DUPLICATE_REVISION_SUB_CATEGORIES,
};
