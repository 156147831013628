import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import storage from 'utils/localStorageHandler';

import { OUTPUT_FORMATS_MAP } from 'constants/order';

import {
  ordersSetStatusCheckFetch,
  ordersSetReservationOver,
  ordersSetReservedReceive,
  ordersHideInLists,
} from 'store/orders/actions';

import { getTakenOrderNotifications } from 'store/ordersNotifications/selectors';
import { removeTakenNotification } from 'store/ordersNotifications/actions';

import OrderTags from 'components/OrderTags';
import IconTag from 'components/shared/IconTag';

import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';
import getPreferredHelp from 'utils/getPreferredHelp';
import utils from 'utils';
import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';

import Icon from './Icon';
import ReservedCounter from './ReservedCounter';


const SIZES_ORDER = {
  minute: 'm',
  minutes: 'm',
  pages: 'p',
  page: 'p',
  words: 'w',
  word: 'w',
  slides: 's',
  slide: 's',
  source: 'src',
  sources: 'src',
};

const GROUP_BONUSES = {
  base: 0.35,
  'B-pro': 0.20,
  'A-pro': 0.10,
};

const onOpenClick = event => event.stopPropagation();

class OrdersTableRow extends Component {
  static renderPinIcon() {
    return (
      <div className="tooltip tooltip__icon tooltip-table tooltip-left tooltip-top">
        <div className="pin-icon tooltip-trigger" />
        <div className="tooltip-content">
          <strong className="mb4 fsXSm block" style={{ color: '#637280' }}>Pinned Order</strong>
          <p>Top priority, filters do not apply.</p>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.onAnimationHandler = this.onAnimationHandler.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onGotItClick = this.onGotItClick.bind(this);
    this.renderOrderNumber = this.renderOrderNumber.bind(this);
    this.updateUserViewedCount = this.updateUserViewedCount.bind(this);
    this.renderInlineIcons = this.renderInlineIcons.bind(this);
    this.getPriceEarns = this.getPriceEarns.bind(this);
    this.renderTypeOfWorkColumValue = this.renderTypeOfWorkColumValue.bind(this);
    this.renderSizeColumnValue = this.renderSizeColumnValue.bind(this);
    this.renderPriceWillLearnColumnValue = this.renderPriceWillLearnColumnValue.bind(this);
    this.renderAPlusLevelEarns = this.renderAPlusLevelEarns.bind(this);

    this.state = {
      newOrderViewed: false,
      orderReadyForRemove: false,
      isOnViewPort: false,
      isNewViewed: false,
    };
  }
  componentDidMount() {
    const { isTaken } = this.props;
    if (isTaken && !this.takenOrderTimer) {
      window.document.addEventListener('scroll', this.onScroll);
      this.onScroll();
    }
  }

  componentDidUpdate(prevProps) {
    const { isTaken } = this.props;
    const { isTaken: isTakenBefore } = prevProps;

    if (isTaken !== isTakenBefore && !this.takenOrderTimer) {
      window.document.addEventListener('scroll', this.onScroll);
      this.onScroll();
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener('scroll', this.onScroll);
    if (this.newOrderTimer) clearTimeout(this.newOrderTimer);
    if (this.takenOrderTimer) clearTimeout(this.takenOrderTimer);
  }

  onGotItClick(event) {
    const { checkReserve } = this.props;
    checkReserve();
    event.stopPropagation();
  }


  onAnimationHandler() {
    const {
      isTaken = false, removeOrder, isSelected, order,
    } = this.props;

    if (!isTaken || isSelected) return;
    removeOrder(order.ready_reserved);
  }

  onScroll() {
    const { scrollY, innerHeight } = window;

    if (!this.orderItemEl) return;

    const { offsetTop, offsetHeight } = this.orderItemEl;
    this.setState({ isOnViewPort: (offsetTop > scrollY) && ((offsetTop + offsetHeight) < (scrollY + innerHeight)) });
  }

  updateUserViewedCount() {
    const { is_viewed: isViewed } = this.props.order;

    if (isViewed) return;

    const userViewedCount = storage.get('userViewedCount') || 0;
    if (userViewedCount < 0) return;
    storage.set('userViewedCount', userViewedCount + 1);
    window.dispatchEvent(new Event('storage'));
  }

  resetTimeout(callback, event) {
    if (this.newOrderTimer) {
      clearTimeout(this.newOrderTimer);
      this.setState({ newOrderViewed: true });
    }
    callback(event);
  }

  renderCounter() {
    const { order, setReservationOver } = this.props;
    if (order.isReservationOver) {
      return (
        <span className="text-warning">Reservation time is over. <a onClick={this.onGotItClick}>Got it.</a></span>
      );
    }
    return (
      <span>
        Reserved: <ReservedCounter time={order.reserve_deadline} onOver={() => setReservationOver(order.reserved_by_me)} />
      </span>
    );
  }

  renderDeadline() {
    const { order } = this.props;
    const momentDeadLine = moment(order.writer_deadline);
    const hoursDiff = Math.round(momentDeadLine.diff(moment(), 'hours', true));

    if (hoursDiff < 0 || hoursDiff >= 96) {
      return (<span>{formatTime(momentDeadLine, 'dt')}</span>);
    }
    const className = classNames({ 'text-warning': hoursDiff < 24 });
    return (
      <span>
        {`${formatTime(momentDeadLine, 'dt')} / `}
        <b className={className}>{hoursDiff}h</b>
      </span>
    );
  }

  renderOrderNumber(order, isAPlusPro, isStem) {
    return (
      <div className={classNames({ 'col-4 order-number': !isAPlusPro && !isStem, 'col-5': isAPlusPro || isStem })}>
        {
          !isAPlusPro && !isStem ?
            <span className="order-number__text" title={order.number.length > 14 ? order.number : ''}>{order.number}</span>
            :
            <Fragment>{order.number}</Fragment>
        }
        <a href={`/order/${order._id}?show_additional=true`} target="_blank" rel="noopener noreferrer" onClick={event => this.resetTimeout(onOpenClick, event)}>
          <Icon className="svg-icon" iconName="open-new-window" />
        </a>
      </div>
    );
  }

  renderInlineIcons() {
    const { order = {} } = this.props;
    const isNTorder = utils.isNTorder(order);

    const { is_online_dashboard: isOnlineDashboard } = order;

    if (isNTorder || isOnlineDashboard) {
      const inlineIcons = {
        'icon-online-order': isOnlineDashboard,
        'icon-nt-order': isNTorder,
      };
      return (
        <div className="tags-list">
          {
            Object.entries(inlineIcons).reduce((prev, [key, values]) => {
              if (values) {
                prev.push(<IconTag key={key} iconName={key} callFromTable />);
              }
              return prev;
            }, [])
          }
        </div>
      );
    }

    return null;
  }

  getPriceEarns(pricePerHour) {
    const { order, profile } = this.props;
    const { writer_price_base: writerPriceBase, writer_price: writerPrice } = order;
    const { qc_full_group: qcFullGroup } = profile;

    return ((pricePerHour || writerPriceBase) * (GROUP_BONUSES[qcFullGroup] || GROUP_BONUSES.base) + (pricePerHour || writerPrice)).toFixed(2);
  }

  renderTypeOfWorkColumValue() {
    const { order = {} } = this.props;
    const isNTorder = utils.isNTorder(order);
    const { type } = order;

    if (!isNTorder) return type;

    const _helpFormats = getPreferredHelp(order, true);

    if (isNTorder && _helpFormats.length > 0) {
      return (
        <div className="row vertical">
          <div className="mr4" style={{ minWidth: '85px' }}>Tutoring order</div>
          <div className="tooltip tooltip__icon tooltip-right tooltip-top">
            <Icon className="svg-icon tooltip-trigger tooltip-trigger-size icon-table-blue-info" iconName="icon-blue-info" />
            <ul className="tooltip-content max-200-tooltip fit-content-tooltip list-tooltip white-space-normal-tooltip">
              {_helpFormats.map(hF => <li className="row">{hF}</li>)}
            </ul>
          </div>
        </div>
      );
    }

    return 'Tutoring order';
  }

  renderSizeColumnValue(orderSizes, isSizeTooltip, orderSizeMH, isHaveAcceptedEstimate) {
    const { order = {} } = this.props;
    const isNTorder = utils.isNTorder(order);

    const { size: orderSize, let_tutor_decide: letTutorDecide } = order;


    if (!isNTorder || (isNTorder && (!letTutorDecide || isHaveAcceptedEstimate))) {
      if (isSizeTooltip) {
        return (
          <div className="col-2 block tooltip tooltip-top tooltip-table tooltip-right">
            <a className="tooltip-trigger">{orderSizes}</a>
            <div className="tooltip-content">
              {orderSizes}
            </div>
          </div>
        );
      }

      return (
        <div className="col-2">
          {orderSizes || orderSizeMH || orderSize}
        </div>
      );
    }

    return (
      <div className="col-2 tooltip tooltip__icon tooltip-right tooltip-top">
        <div className="tooltip-trigger tooltip-trigger-size">
          Estimate required
        </div>
        <div className="tooltip-content max-200-tooltip fit-content-tooltip list-tooltip white-space-normal-tooltip">
          <div className="mb2">Check task details and send your estimate - how much time do you need to complete this task.</div>
          <ul>
            <li className="row">check task instructions;</li>
            <li className="row">join chat;</li>
            <li className="row">send your estimate to client.</li>
          </ul>
          <div className="mb2">After you provide your estimate student can:</div>
          <ul>
            <li className="row">agree with your estimate and make the payment. In that case you will be able to accept and complete the order;</li>
            <li className="row">decline your estimate.</li>
          </ul>
        </div>
      </div>
    );
  }

  renderPriceWillLearnColumnValue(orderSizes, isHaveAcceptedEstimate) {
    const { profile, order } = this.props;
    const {
      size: orderSize, writer_price: writerPrice,
      is_recommended: isRecommended = true,
      let_tutor_decide: letTutorDecide,
      writer_per_hour_price: writerPerHourPrice,
    } = order;
    const strongClassName = classNames({ 'recommended-price row': isRecommended });
    const isNTorder = utils.isNTorder(order);

    const isStem = utils.isStem(profile);

    if (isStem) {
      return (
        <div className="col-4">
          {utils.getMinutesSize(orderSizes || orderSize)}
        </div>
      );
    }

    return (
      <div className="col-4 tooltip tooltip__icon tooltip-right tooltip-top">
        <span className={strongClassName}>
          {isNTorder && letTutorDecide && !isHaveAcceptedEstimate ? `$${writerPerHourPrice}/hour` : `$${writerPrice.toFixed(2)}`}
          {isRecommended && <Icon className="svg-icon tooltip-trigger" iconName="order-is-recommended" styles={{ width: '16px', height: '16px', marginLeft: '4px' }} />}
          <div className="tooltip-content">
            <strong className="mb4 fsXSm block" style={{ color: '#637280' }}>Recommended</strong>
            <p>Order is specifically selected for you and have higher price.</p>
          </div>
        </span>
      </div>
    );
  }

  renderAPlusLevelEarns(isHaveAcceptedEstimate) {
    const { order, isAPlusPro, isStem } = this.props;

    if (isAPlusPro || isStem) return null;

    const isNTorder = utils.isNTorder(order);
    const { let_tutor_decide: letTutorDecide, writer_per_hour_price: writerPerHourPrice, is_recommended: isRecommended } = order;

    let priceEarns = this.getPriceEarns();

    if (isNTorder && letTutorDecide && !isHaveAcceptedEstimate) {
      priceEarns = `${this.getPriceEarns(writerPerHourPrice)}/hour`;
    }

    const priceAPlusLevelClassName = classNames('text-success', { 'recommended-price ': isRecommended });

    return (
      <div className="col-3">
        <div className="tooltip tooltip__icon tooltip-table tooltip-right tooltip-top">
          <a className="tooltip-trigger">
            <span className={priceAPlusLevelClassName}>
              ${priceEarns}
            </span>
          </a>
          <div className="tooltip-content">
            Achieve <b>4.7+</b> average client score (based on 10+ scores) <br />
            to join <b>A+ level</b>!
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      order,
      className,
      onClick,
      isReserved,
      profile,
      isAPlusPro,
      writer_deadline,
      isNewOrder,
      isTaken,
      isDe,
      hasClientChatNotification,
    } = this.props;

    if (!order) return null;

    const {
      writer_in_chat: writerInChat = false,
      current_writer_in_chat: currentWriterInChat,
    } = order;

    const priceEarns = this.getPriceEarns();
    const isHaveAcceptedEstimate = utils.isHaveAcceptedEstimate(order);

    const {
      newOrderViewed, orderReadyForRemove, isOnViewPort, isNewViewed,
    } = this.state;
    const isStem = utils.isStem(profile);
    let rowClassName = '';
    if (isTaken) {
      rowClassName = classNames(
        className,
        {
          taken: isTaken,
          'taken--hide': orderReadyForRemove,
        },
      );
    } else {
      rowClassName = classNames(
        className,
        { 'reserve-by-me': order.reserved_by_me && !currentWriterInChat },
        { reserved: order.reserved && !order.reserved_by_me && !writerInChat },
        { pinned: order.is_pinned },
        { 'not-read': !order.is_viewed },
        { 'new-order': isNewOrder && !isNewViewed },
        { 'new-order--with-animation': isNewOrder && !this.newOrderTimer && !isNewViewed },
        { 'new-order--viewed': isNewOrder && newOrderViewed && !isNewViewed },
      );
    }

    if (!isTaken && isNewOrder && !this.newOrderTimer) {
      this.newOrderTimer = setTimeout(() => {
        this.setState({ newOrderViewed: true });
      }, 5000);
    }

    if (isTaken && !this.takenOrderTimer && isOnViewPort) {
      this.takenOrderTimer = setTimeout(() => {
        this.setState({ orderReadyForRemove: true });
      }, 2000);
    }

    let orderSizes = null;
    let orderSizeMH = null;
    let isSizeTooltip = false;
    if (order.is_complex && order.extras && order.extras.length > 0) {
      orderSizes = order.extras.filter(s => s.size && s.payment_status === 'Paid');
      if (orderSizes.length > 1) {
        isSizeTooltip = true;
        orderSizes = orderSizes.map((s) => {
          const [size, type] = s.size.split(' ');
          if (type === 'minutes' && !isDe) {
            return convertMinsToHrsMins(size, false, ['h', 'm']);
          }
          return size + SIZES_ORDER[type];
        }).join(' /');
      } else {
        orderSizes = orderSizes.map((s) => {
          const [size, type] = s.size.split(' ');
          if (type === 'minutes' && !isDe) {
            return convertMinsToHrsMins(size);
          }
          return s.size;
        }).toString();
      }
    }

    if (order.size && !isDe) {
      const [size, type] = order.size.split(' ');
      if (type === 'minutes') {
        orderSizeMH = convertMinsToHrsMins(size);
      }
    }

    return (
      <div
        className={rowClassName}
        ref={ref => this.orderItemEl = ref}
        onClick={(event) => {
          this.resetTimeout(onClick, event);
          this.setState({ isNewViewed: true });
          this.updateUserViewedCount();
        }}
        onAnimationEnd={this.onAnimationHandler}
      >
        <div className="col-1 ta-center small-col">
          {order.is_pinned ? OrdersTableRow.renderPinIcon() : (isReserved && !order.isReservationOver) ? <div className="time-icon" /> : null}
        </div>
        {this.renderOrderNumber(order, isAPlusPro, isStem)}
        <div className="col-6"><span className="text-ellipsis no-wrap">{order.subject}</span>{this.renderInlineIcons()}</div>
        <div className="col-3">{this.renderTypeOfWorkColumValue()}</div>
        {this.renderPriceWillLearnColumnValue(orderSizes, isHaveAcceptedEstimate)}
        {this.renderAPlusLevelEarns(isHaveAcceptedEstimate)}
        { this.renderSizeColumnValue(orderSizes, isSizeTooltip, orderSizeMH, isHaveAcceptedEstimate)}
        <div className="col-5 no-wrap">{this.renderDeadline()}</div>
        {((!isStem && !isTaken) || (isStem && isReserved)) &&
          <div className={classNames('col-4', { 'reserve-time': isReserved && !writerInChat && !currentWriterInChat })}>{isReserved ? (currentWriterInChat || writerInChat) ? <div className={classNames('row', { 'table-row-notification': currentWriterInChat && hasClientChatNotification })}>{currentWriterInChat ? 'You are in chat now' : 'Tutor is in chat'}</div> : this.renderCounter() : <OrderTags order={order} user={profile} callFromTable />}</div>
        }
        {isTaken && order.ready_reserved && <div className="col-4">Reserved</div>}
        {isTaken && !order.ready_reserved && <div className="col-4">Taken</div>}
      </div>
    );
  }
}

const mapStateToProps = (state, { number, isSelected }) => {
  const order = state.orders[number];
  if (!order) {
    return {
      order,
    };
  }
  const profile = state.user;
  const isNewOrder = order.is_new_order;
  const isTaken = getTakenOrderNotifications(state).includes(order.number);
  const isDe = state.user && state.user.profile_type === 'D';
  const hasClientChatNotification = Boolean((state.clientChatNotifications.notifications.find(it => it.node === order.jabber_node) || {}).count) || false;

  return {
    order, profile, isNewOrder, isTaken, isSelected, isDe, hasClientChatNotification,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  checkReserve: () => dispatch(ordersSetStatusCheckFetch(ownProps.number)),
  setReservationOver: (isMyReserve = false) => {
    if (isMyReserve) {
      dispatch(ordersSetReservationOver(ownProps.number));
    } else {
      dispatch(ordersSetStatusCheckFetch(ownProps.number));
    }
  },
  removeOrder: (readyReserved = false) => {
    if (!readyReserved) dispatch(ordersHideInLists(ownProps.number));
    else {
      dispatch(ordersSetReservedReceive(ownProps.number));
    }
    dispatch(removeTakenNotification(ownProps.number));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTableRow);
