import { createSelector } from 'reselect';
import moment from 'moment-timezone';

import stable from 'stable';

const getTaskList = state => state.user.tasks.items || [];
const getSubjects = state => state.user.subjects;
const getProfile = state => state.user;
const getAgreementInfo = state => !state.user.profile_tag_policies_agree || !state.user.profile_tag_terms_agree;
const getPmRequestDisabled = state => state.user?.pm_setup_requested_disable_at;
const getPmStartRequested = state => state.user?.pm_setup_requested;

const statusWeights = {
  new: 0,
  'in-progress': 1,
  done: 2,
};
const taskSorter = (a, b) => {
  const aStatus = statusWeights[a.status];
  const bStatus = statusWeights[b.status];
  if (aStatus < bStatus) {
    return -1;
  }
  if (aStatus > bStatus) {
    return 1;
  }
  if (a.deadline_date && !b.deadline_date) {
    return 1;
  }
  if (!a.deadline_date && b.deadline_date) {
    return -1;
  }

  return moment(b.deadline_date).diff(moment(a.deadline_date));
};

const getTasksSorted = createSelector(
  [getTaskList],
  taskList => stable(taskList, taskSorter),
);

export {
  getTasksSorted,
  getSubjects,
  getProfile,
  getAgreementInfo,
  getPmStartRequested,
  getPmRequestDisabled,
};
