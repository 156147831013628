/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import payoneer from 'img/icons/payoneer.png';
import skrill from 'img/icons/skrill.png';
import wireTransfer from 'img/icons/wire_transfer.png';
import alarm from 'img/icons/alarm.svg';
import wise from 'img/icons/wise.png';

import Icon from 'components/Icon';
import Modal from 'components/Modal';

import { userSetFieldFetch, userClearPaymentError } from 'store/user/actions';

import storage from 'utils/localStorageHandler';
import validation from 'utils/validation';
import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';

import { PaymentsTypeDropdown } from '../Controls';

import WelcomeWTModal from '../WireTransferModals/WelcomeModal';
import WTFormModal from '../WireTransferModals/FormModal';
import ThanksModal from '../WireTransferModals/ThanksModal';

const methods = {
  SKRILL: 'SKRILL',
  Payoneer: 'Payoneer',
  WireTransfer: 'WireTransfer',
  Wise: 'Wise',
};

const getMethodName = (method) => {
  switch (method) {
    case methods.SKRILL:
      return 'Skrill';
    case methods.Wise:
      return 'Wise';
    case methods.Payoneer:
      return 'Payoneer';
    case methods.WireTransfer:
      return 'Wire transfer';
    default:
      return null;
  }
};

const getPayoneerLink = token => `https://payouts.payoneer.com/partners/lp.aspx?token=${encodeURIComponent(token)}`;

class PaymentInfoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      skrillValue: '',
      payoneerValue: '',
      payoneerValue2: '',
      wiseValue: '',
      showModal: false,
      showWTWelcomeModal: false,
      showWTFormModal: false,
      showThanksWTModal: false,
    };

    this.onPayoneerSubmit = this.onPayoneerSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSavePayMethod = this.onSavePayMethod.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleWelcomeWireTransferModal = this.toggleWelcomeWireTransferModal.bind(this);
    this.renderWireTransfer = this.renderWireTransfer.bind(this);
    this.toggleFormWireTransferModal = this.toggleFormWireTransferModal.bind(this);
    this.toggleThanksWireTransferModal = this.toggleThanksWireTransferModal.bind(this);
    this.onToggleWarning = this.onToggleWarning.bind(this);
    this.renderWiseForm = this.renderWiseForm.bind(this);
  }

  componentDidMount() {
    const {
      payMethodList, payoneerLink2, payoneerLink3, isDe,
    } = this.props;
    this.setState({
      skrillValue: payMethodList.SKRILL?.value,
      payoneerValue: payMethodList.Payoneer?.value,
      payoneerValue2: !isDe && payoneerLink2 && payMethodList.Payoneer2?.value,
      payoneerValue3: !isDe && payoneerLink3 && payMethodList.Payoneer3?.value,
      wiseValue: payMethodList.Wise?.value,
    });
  }

  onToggleWarning() {
    const { clearError } = this.props;
    clearError();
  }

  onSavePayMethod(value) {
    const { savePayMethodDropdown, payMethod } = this.props;
    savePayMethodDropdown(value, payMethod);
  }

  onInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onPayoneerSubmit(event, __methodItem, __payoneerValue, payoneerName) {
    event.preventDefault();

    const { payMethodList, savePayMethodValue } = this.props;
    const { payoneerValue } = this.state;
    const chosenPayoneerValue = __methodItem ? __payoneerValue : payoneerValue;
    const methodItem = __methodItem || payMethodList.Payoneer;
    const isModified = chosenPayoneerValue !== methodItem.value;

    if (!isModified || !this.isPayoneerValid(__methodItem && __payoneerValue, payoneerName)) {
      return;
    }

    savePayMethodValue(__methodItem ? payoneerName : methods.Payoneer, chosenPayoneerValue);
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  toggleWelcomeWireTransferModal() {
    this.setState({ showWTWelcomeModal: !this.state.showWTWelcomeModal });
  }

  toggleFormWireTransferModal(e, showThanksWTModal = false) {
    this.setState({ showWTFormModal: !this.state.showWTFormModal, showWTWelcomeModal: false, showThanksWTModal });
  }

  toggleThanksWireTransferModal() {
    this.setState({ showThanksWTModal: !this.state.showThanksWTModal });
  }

  isPayoneerValid(__payoneerValue, payoneerName) {
    const { payoneerValue } = this.state;
    let regPayoneer;

    if (__payoneerValue === undefined) {
      regPayoneer = /^Nerdy[0-9]{1,}$/;
    } else if (payoneerName === 'Payoneer2') {
      regPayoneer = /^ETTOutsourceLTD[0-9]{1,}$/;
    } else if (payoneerName === 'Payoneer3') {
      regPayoneer = /^EDSPIREDLIMITEDAccount[0-9]{1,}$/;
    }

    return regPayoneer.test(__payoneerValue || payoneerValue);
  }

  renderSkrillStatus() {
    const { payMethodList } = this.props;
    const methodItem = payMethodList.SKRILL;
    if (methodItem.verified) {
      return (<span className="status-box"><Icon className="svg-icon success" iconName="check-fill" />Verified</span>);
    }
    if (methodItem.pending) {
      return (<span className="status-box"><Icon className="svg-icon" iconName="watch" />In progress</span>);
    }
    return null;
  }

  renderSkrillForm() {
    const {
      payMethodList, savePayMethodValue, changeLocked, isFetchingFields,
    } = this.props;
    const { skrillValue } = this.state;
    const methodItem = payMethodList.SKRILL;
    const isModified = skrillValue !== methodItem.value;
    const isValid = !!skrillValue && validation.isEmail(skrillValue);
    return (
      <form onSubmit={(event) => { event.preventDefault(); isModified && isValid && savePayMethodValue(methods.SKRILL, skrillValue); }}>
        <div className="row vertical">
          <div className="col-3 no-padding">
            <input
              type="text"
              name="skrillValue"
              className="input-text"
              placeholder="Enter your Skrill email"
              value={skrillValue}
              onChange={this.onInputChange}
              disabled={changeLocked}
            />
          </div>
          <div className="col-1">
            {isModified &&
              <button className="btn btn-bright-success" type="submit" disabled={!isValid || isFetchingFields}>Verify</button>
            }
            {!isModified && this.renderSkrillStatus()}
          </div>
        </div>
      </form>
    );
  }

  renderWiseStatus() {
    const { payMethodList } = this.props;
    const methodItem = payMethodList.Wise || {};
    if (methodItem.verified) {
      return (<span className="status-box"><Icon className="svg-icon success" iconName="check-fill" />Verified</span>);
    }
    if (methodItem.pending) {
      return (<span className="status-box"><Icon className="svg-icon" iconName="watch" />In progress</span>);
    }
    return null;
  }

  renderWiseForm() {
    const {
      payMethodList, savePayMethodValue, changeLocked, isFetchingFields,
    } = this.props;
    const { wiseValue } = this.state;
    const methodItem = payMethodList.Wise;
    const isModified = wiseValue !== methodItem.value;
    const isValid = !!wiseValue && validation.isEmail(wiseValue);
    return (
      <form onSubmit={(event) => { event.preventDefault(); isModified && isValid && savePayMethodValue(methods.Wise, wiseValue); }}>
        <div className="row vertical">
          <div className="col-3 no-padding">
            <input
              type="text"
              name="wiseValue"
              className="input-text"
              placeholder="Enter your Wise email"
              value={wiseValue}
              onChange={this.onInputChange}
              disabled={changeLocked}
            />
          </div>
          <div className="col-1">
            {isModified &&
              <button className="btn btn-bright-success" type="submit" disabled={!isValid || isFetchingFields}>Verify</button>
            }
            {!isModified && this.renderWiseStatus()}
          </div>
        </div>
      </form>
    );
  }

  renderPayoneerStatus(__methodItem, __payoneerValue, payoneerName) {
    const {
      payMethodList, isFetchingFields, payoneerLink2, payoneerLink3,
    } = this.props;
    const { payoneerValue } = this.state;
    const methodItem = __methodItem || payMethodList.Payoneer;
    // const showMessageBrideford = isHiredAfter ? /^Nerdy.*$/.test(payoneerValue) : /^Brideford.*$/.test(payoneerValue);
    const showMessageBrideford = /^Brideford.*$/.test(payoneerValue);
    const chosenPayoneerValue = __methodItem ? __payoneerValue : payoneerValue;
    const isModified = chosenPayoneerValue !== methodItem.value;
    const isValid = this.isPayoneerValid(__payoneerValue, payoneerName);

    if (showMessageBrideford) {
      return (
        <div className="status-box tooltip">
          <Icon className="svg-icon" iconName="error" /><a className="tooltip-trigger">Error</a>
          <div className="tooltip-content">
            Oops, this ID is not valid any more.<br />
            Simply follow the payment link below to set up a new one, it won&apos;t take more then 3 minutes of your time.
          </div>
        </div>
      );
    }

    const { verified, pending } = methodItem;

    if (!chosenPayoneerValue && payoneerLink3) {
      if (!payMethodList.Payoneer.value && payMethodList.Payoneer3 && !payMethodList.Payoneer3.value) {
        return (
          <div className="status-box tooltip">
            <Icon className="svg-icon" iconName="error" /><a className="tooltip-trigger">Error</a>
            <div className="tooltip-content tooltip-content__300">
              You need to verify all funding sources before being able to select
              Payoneer as a primary payment method. Follow the payment links below
              to set up funding sources on Payoneer.
            </div>
          </div>
        );
      }

      if (!methodItem.value) {
        return (
          <div className="status-box tooltip">
            <Icon className="svg-icon" iconName="error" /><a className="tooltip-trigger">Error</a>
            <div className="tooltip-content tooltip-content__300">
              You haven’t verified all funding sources yet.
              Please follow the payment links below to set up and verify the
              remaining funding source(s) on Payoneer.
              Once all funding sources are verified, you can choose
              Payoneer as your primary method and request withdrawals to it.
            </div>
          </div>
        );
      }
    }

    if (!chosenPayoneerValue && payoneerLink2) {
      if (!payMethodList.Payoneer.value && payMethodList.Payoneer2 && !payMethodList.Payoneer2.value) {
        return (
          <div className="status-box tooltip">
            <Icon className="svg-icon" iconName="error" /><a className="tooltip-trigger">Error</a>
            <div className="tooltip-content tooltip-content__300">
              You need to verify all funding sources before being able to select Payoneer as a primary payment method.<br />
              Follow the payment links below to set up funding sources on Payoneer.
            </div>
          </div>
        );
      }

      if (!methodItem.value) {
        return (
          <div className="status-box tooltip">
            <Icon className="svg-icon" iconName="error" /><a className="tooltip-trigger">Error</a>
            <div className="tooltip-content tooltip-content__300">
              You have already verified one funding source.<br />
              Follow another payment link below to set up one more funding source on Payoneer.<br /> After both funding sources are verified, you’ll be able to choose Payoneer as your primary method and request withdrawals to it.
            </div>
          </div>
        );
      }
    }

    if (isModified) {
      return (<button className="btn btn-bright-success" type={__methodItem ? '' : 'submit'} onClick={__methodItem ? e => this.onPayoneerSubmit(e, __methodItem, __payoneerValue, payoneerName) : null} disabled={!isValid || isFetchingFields}>Verify</button>);
    }

    if (verified) {
      return (<span className="status-box"><Icon className="svg-icon success" iconName="check-fill" />Verified</span>);
    }

    if (pending) {
      return (<span className="status-box"><Icon className="svg-icon" iconName="watch" />In progress</span>);
    }
    return null;
  }

  renderPayoneerForm() {
    const {
      changeLocked, payoneerLink2 = null, payoneerLink3 = null,
      payMethodList, isDe,
    } = this.props;
    const { payoneerValue, payoneerValue2, payoneerValue3 } = this.state;

    return (
      <form onSubmit={this.onPayoneerSubmit}>
        <div className="row vertical">
          <div className="col-3 no-padding">
            <input
              type="text"
              name="payoneerValue"
              className="input-text"
              placeholder="NerdyXXXXX"
              value={payoneerValue}
              onChange={this.onInputChange}
              disabled={changeLocked}
            />
          </div>
          <div className="col-1">
            {this.renderPayoneerStatus()}
          </div>
        </div>
        <div />
        {
          payoneerLink2 && !isDe && (
            <>
              <div className="row vertical mt8">
                <div className="col-3 no-padding">
                  <input
                    type="text"
                    name="payoneerValue2"
                    className="input-text"
                    placeholder="ETTOutsourceLTDXXXXX"
                    value={payoneerValue2}
                    onChange={this.onInputChange}
                    disabled={changeLocked}
                  />
                </div>
                <div className="col-1">
                  {this.renderPayoneerStatus(payMethodList.Payoneer2 || {}, payoneerValue2, 'Payoneer2')}
                </div>
              </div>
              <div />
            </>
          )
        }
        {
          payoneerLink3 && (
            <>
              <div className="row vertical mt8">
                <div className="col-3 no-padding">
                  <input
                    type="text"
                    name="payoneerValue3"
                    className="input-text"
                    placeholder="EDSPIREDLIMITEDAccountXXX"
                    value={payoneerValue3}
                    onChange={this.onInputChange}
                    disabled={changeLocked}
                  />
                </div>
                <div className="col-1">
                  {this.renderPayoneerStatus(payMethodList.Payoneer3 || {}, payoneerValue3, 'Payoneer3')}
                </div>
              </div>
              <div />
            </>
          )
        }
      </form>
    );
  }

  renderWireTransfer() {
    const {
      userName, userCountry, countryList, savePayMethodValue, payMethodList = {},
    } = this.props;
    const { showWTWelcomeModal, showWTFormModal } = this.state;
    const WTData = payMethodList.WireTransfer || {};

    const {
      pending, verified, rejected, valid, value: WTValue = {},
    } = WTData;

    if (pending) {
      return (<div className="row vertical left pr10"><Icon className="svg-icon mr8" iconName="watch" />Checking</div>);
    }

    if (verified) {
      return (
        <div className="row vertical left">
          <div className="status-box pr10"><Icon className="svg-icon success" iconName="check-fill" />Verified</div>
        </div>
      );
    }

    const notValidOrReject = rejected || (valid !== undefined && valid === false);
    const isValidButNotVerified = !verified && valid;
    const showAgain = notValidOrReject || isValidButNotVerified;

    const btnText = showAgain ? 'Activate once again' : 'Activate payment method';

    if (showAgain) {
      const {
        province, bank_name, interm_bank_name, bank_country, city, bank_address,
        bank_swift, interm_bank_address, country_of_residency, postal_code,
        beneficiary_address, full_name, interm_bank_account, interm_bank_country,
        interm_bank_swift, bank_account, default_currency, bank_ifsc,
      } = WTValue;
      storage.set('wire-transfer-data', JSON.stringify({
        personalInfo: {
          full_name,
          default_currency,
          country_of_residency,
        },
        bankInfo: {
          bank_account,
          bank_swift,
          bank_country,
          bank_name,
          bank_address,
          bank_ifsc,
          isBankAccountValid: true,
          isBankSwiftValid: true,
          isBankIFSCValid: Boolean(bank_ifsc),
        },
        bankIntermediaryInfo: {
          interm_bank_account,
          interm_bank_swift,
          interm_bank_country,
          interm_bank_name,
          interm_bank_address,
          isIntermBankAccountValid: true,
          isIntermBankSwiftValid: true,
        },
        addressInfo: {
          beneficiary_address,
          city,
          province,
          postal_code,
        },
      }));
    }

    return (
      <div className={classNames('row vertical', { 'space-between': showAgain })}>
        {
          showWTWelcomeModal &&
          <WelcomeWTModal toggleWelcomeWireTransferModal={this.toggleWelcomeWireTransferModal} toggleFormWireTransferModal={this.toggleFormWireTransferModal} />
        }
        {
          showWTFormModal &&
          <WTFormModal toggleFormWireTransferModal={this.toggleFormWireTransferModal} profile={{ userName, userCountry }} countryList={countryList} savePayMethodValue={savePayMethodValue} />
        }
        <button className="btn btn-default transparent" onClick={showAgain ? this.toggleFormWireTransferModal : this.toggleWelcomeWireTransferModal}>{btnText}</button>
        {
          notValidOrReject && <div className="mr12 as-error-text">Rejected</div>
        }
      </div>
    );
  }

  renderCurrentMethodText() {
    const {
      payMethodList, payMethod, payoneerLink2,
      payoneerLink3,
    } = this.props;
    const methodItem = payMethodList[payMethod];
    const paymentName = getMethodName(payMethod);

    if (paymentName === 'Payoneer' && payoneerLink2 && payoneerLink3) {
      return (<b>{paymentName}</b>);
    }

    return (
      <b>{paymentName} ({methodItem.value.bank_account || methodItem.value})</b>
    );
  }

  render() {
    const {
      payoneerLink, changeLocked,
      payMethod, isLoadingPayMethod,
      isDe, isPayMethodError = false,
      payoneerLink2, availablePayMethods = [],
      payMethodList,
      isDisabledPayment,
      payoneerLink3,
    } = this.props;
    const { showThanksWTModal } = this.state;
    const className = classNames('payment-info-box', { disabled: changeLocked });

    const classNameSKRILL = classNames('payment-info', { active: payMethod === methods.SKRILL });
    const classNamePayoneer = classNames('payment-info', { active: payMethod === methods.Payoneer });
    const classNameWireTransfer = classNames('payment-info', { active: payMethod === methods.WireTransfer });
    const classNameWise = classNames('payment-info', { active: payMethod === methods.wise });

    return (
      <div>
        {!changeLocked && payMethod &&
          <div className="bg-context">
            Current withdrawal method: {this.renderCurrentMethodText()}
          </div>
        }

        {changeLocked && !isDisabledPayment &&
          <div className="bg-context alert-danger">
            Primary payment method modification will be available after <b>{formatTime(changeLocked, 'd')}</b>
          </div>
        }

        {!payMethod && !isLoadingPayMethod &&
          <div className="bg-context alert-danger">
            You are welcome to start working on orders before setting up the payment method! The money will be stored on your balance until you are ready to withdraw.
          </div>
        }
        {
          isPayMethodError && (
            <Modal onClose={this.onToggleWarning} className="modal-sm ta-center">
              <img className="modal-icon" src={alarm} alt="" />
              <h2 className="title mb30">You cannot change <br /> payment method now</h2>
              <button type="button" className="btn btn-bright btn-big" onClick={this.onToggleWarning}>Got it</button>
            </Modal>
          )
        }

        <div className={className}>
          <div className="payment-info payment-info--dropdown">
            <div className="row vertical">
              <div className="col-1">
                <b>Primary payment option:</b>
              </div>
              <div className="col-2">
                <div className="row vertical">
                  <div className="col-3">
                    {(availablePayMethods.length > 0 || isLoadingPayMethod) &&
                      <PaymentsTypeDropdown
                        payments={availablePayMethods}
                        onChange={e => this.onSavePayMethod(e)}
                        payMethod={payMethod}
                        disabled={isLoadingPayMethod || changeLocked || isDisabledPayment}
                      />
                    }
                    {!availablePayMethods.length && !isLoadingPayMethod && !changeLocked &&
                      <div className="text-danger">Please set up at least one payment option below</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            payMethodList[methods.SKRILL] && (
              <div className={classNameSKRILL}>
                <div className="row vertical">
                  <div className="col-1">
                    <img className="img-payment" src={skrill} alt="" width="47px" />
                  </div>
                  <div className="col-2">
                    {this.renderSkrillForm()}
                  </div>
                </div>
              </div>
            )
          }
          {payMethodList[methods.Wise] && (
            <div className={classNameWise}>
              <div className="row vertical">
                <div className="col-1">
                  <img className="img-payment" src={wise} alt="" width="68px" />
                </div>
                <div className="col-2">
                  {this.renderWiseForm()}
                </div>
              </div>
            </div>
          )}
          {
            payMethodList[methods.Payoneer] && (
              <div className={classNamePayoneer}>
                <div className="row vertical">
                  <div className="col-1">
                    <img className="img-payment" src={payoneer} alt="" width="68px" />
                  </div>
                  <div className="col-2">
                    {this.renderPayoneerForm()}
                  </div>
                </div>
              </div>
            )
          }
          <div className="additional-info">
            <div className="link-item"><a href="https://www.payoneer.com/accounts" target="_blank" rel="noopener noreferrer">Registration link</a></div>
            {!!payoneerLink && !payoneerLink2 && !payoneerLink3 &&
              <div className="link-item"><a href={payoneerLink} target="_blank" rel="noopener noreferrer">Payment link</a></div>
            }
            {!!payoneerLink && payoneerLink2 && !isDe &&
              <div className="link-item"><a href={payoneerLink} target="_blank" rel="noopener noreferrer">Payment link for Nerdy</a></div>
            }
            {!!payoneerLink2 && !isDe &&
              <div className="link-item"><a href={payoneerLink2} target="_blank" rel="noopener noreferrer">Payment link for ETTOutsourceLTD</a></div>
            }
            {!!payoneerLink3 &&
              <div className="link-item"><a href={payoneerLink3} target="_blank" rel="noopener noreferrer">Payment link for EDSPIREDLIMITEDAccount</a></div>
            }
          </div>
          {
            !isDe && (
              <div className={classNameWireTransfer}>
                <div className="row vertical">
                  <div className="col-1">
                    <img className="img-payment" src={wireTransfer} alt="" width="68px" />
                  </div>
                  <div className="col-2">
                    {this.renderWireTransfer()}
                  </div>
                </div>
              </div>
            )
          }
        </div>
        {
          showThanksWTModal &&
          <ThanksModal toggleThanksWireTransferModal={this.toggleThanksWireTransferModal} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user = {} } = state;
  const {
    fetchingFields, pay_methods_list, pay_method, isPayMethodError = false,
    available_pay_methods: availablePayMethods = [],
  } = user;
  const isDe = state.user && (state.user.profile_type === 'D');

  return {
    payMethodList: pay_methods_list,
    payMethod: pay_method,
    isLoadingPayMethod: fetchingFields.indexOf('pay_method') > -1,
    isSkrillReqsMet: !!user.name && !!user.country && user.passport_verified && user.profile_residence_verified,
    payoneerLink: !user.profile_payoneer_reg_token ? null : getPayoneerLink(user.profile_payoneer_reg_token),
    payoneerLink2: user.profile_payoneer_reg_token2 && getPayoneerLink(user.profile_payoneer_reg_token2),
    payoneerLink3: user.profile_payoneer_reg_token3 && getPayoneerLink(user.profile_payoneer_reg_token3),
    changeLocked: user.payment_change_locked,
    isDisabledPayment: user.pay_method_disabled,
    isFetchingFields: fetchingFields.length > 0,
    userName: user.name,
    userCountry: user.country,
    countryList: (state.settings && state.settings.countryList) || null,
    isDe,
    isPayMethodError,
    availablePayMethods,
  };
};

const mapDispatchToProps = dispatch => ({
  savePayMethod: event => dispatch(userSetFieldFetch('pay_method', event.target.value)),
  savePayMethodDropdown: (value, oldValue) => dispatch(userSetFieldFetch('pay_method', value, oldValue)),
  savePayMethodValue: (name, value) => dispatch(userSetFieldFetch('set_pay_method', { name, value })),
  clearError: () => dispatch(userClearPaymentError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoForm);
