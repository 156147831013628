import React from 'react';

import classNames from './../utils/classNames';

const Radio = props => {
    const { name, value, children, checked, onChange, disabled, className } = props;
    const classNameRadio = classNames(className, 'radiobutton', { 'disabled': disabled });
    return (
        <label className={classNameRadio}>
            <input type="radio" name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} />
            <i className="radio-icon"></i>
            <span className="radio-label">
                {children}
            </span>
        </label>
    );
};

export default Radio;
