import React from 'react';
import classNames from './../utils/classNames';

const UploadButton = (props) => {
  const { children, onChange, multiple, className, inputRef, disabled } = props;
  const btnClassName = classNames(className, 'btn-upload', { disabled });
  return (
    <label className={btnClassName}>
      {children}
      <input type="file" onChange={onChange} multiple={multiple} ref={inputRef} disabled={disabled} />
    </label>
  );
};
export default UploadButton;
