const faqCats = {
  myaccount: {
    id: 'myaccount',
    text: 'My account',
    icon: 'faq-account',
    subCategories: {
      settingsAndAvailability: {
        id: 'settingsAndAvailability',
        text: 'Settings and availability',
      },
      bonuses: {
        id: 'bonuses',
        text: 'Bonuses',
      },
      finance: {
        id: 'finance',
        text: 'Finances',
      },
      terminationOfCooperation: {
        id: 'terminationOfCooperation',
        text: 'Termination of cooperation',
      },
    },
  },
  workWithOrders: {
    id: 'workWithOrders',
    text: 'Work with orders',
    icon: 'faq-orders',
    subCategories: {
      acceptingOrders: {
        id: 'acceptingOrders',
        text: 'Accepting orders',
      },
      typesOfOrders: {
        id: 'typesOfOrders',
        text: 'Types of orders',
      },
      orderInWork: {
        id: 'orderInWork',
        text: 'Order in work',
      },
      revision: {
        id: 'revision',
        text: 'Revision',
      },
      plagiarism: {
        id: 'plagiarism',
        text: 'Plagiarism',
      },
      qualityControl: {
        id: 'qualityControl',
        text: 'Quality control',
      },
      tutoringOrders: {
        id: 'tutoringOrders',
        text: 'Tutoring orders',
      },
    },
  },
  contactUs: {
    id: 'contactUs',
    text: 'Contact us',
    icon: 'faq-contact',
    subCategories: {
      orderRelatedIssues: {
        id: 'orderRelatedIssues',
        text: 'Order related issues',
      },
      jobRelatedIssues: {
        id: 'jobRelatedIssues',
        text: 'Job related issues',
      },
      // financeRelatedIssues: {
      //   id: 'financeRelatedIssues',
      //   text: 'Finance related issues',
      // },
    },
  },
};

let faqFlatCats = {};
const faqCatsMap = Object.values(faqCats);
faqCatsMap.forEach((faqCat) => {
  faqFlatCats = { ...faqFlatCats, ...faqCat.subCategories };
});
const paymentMethodsCategory = 'paymentmethods';
const guidesCats = {
  general: {
    id: 'general',
    text: 'General',
  },
  paymentmethods: {
    id: paymentMethodsCategory,
    text: 'Payment methods',
  },
  academicwriting: {
    id: 'academicwriting',
    text: 'Academic writing',
  },
  formattingstyles: {
    id: 'formattingstyles',
    text: 'Formatting styles',
  },
  badorders: {
    id: 'badorders',
    text: 'Samples. Bad orders',
  },
  goodorders: {
    id: 'goodorders',
    text: 'Samples. Good orders',
  },
};

const faqList = {
  'Why I cannot see any orders?': {
    title: 'Why I cannot see any orders?',
    category: 'settingsAndAvailability',
    text: 'Possible reasons include an unstable order supply due to seasonality of our business. It\'s best to check platform for new orders in the morning and late in the evening CT time zone  (North American Central Time Zone). Please also note our system can perform independent checks as a part of quality maintenance initiative, so if you continue to experience the same issue, do look out for an update on your email, or refer to the Issue Resolution Centre.',
  },
  'What does “Availability” mean?': {
    title: 'What does “Availability” mean?',
    category: 'settingsAndAvailability',
    text: '“Availability” means that you are ready for new order recommendations during the time, specified in your “Availability” widget.',
  },
  'What does it mean my account is “under review”?': {
    title: 'What does it mean my account is “under review”?',
    category: 'settingsAndAvailability',
    text: 'It means we have to check your account due to quality or disciplinary issues, and you temporarily cannot accept any new orders. Do look out for an update on your email, or refer to the Issue Resolution Centre.',
  },
  'How to stop getting emails?': {
    title: 'How to stop getting emails?',
    category: 'settingsAndAvailability',
    text: 'You can manage your notifications in the account settings under “Notifications” tab. At the same time, please keep in mind that in some cases we will have to send you important emails.',
  },
  'How often do I have to log in to my account?': {
    title: 'How often do I have to log in to my account?',
    category: 'settingsAndAvailability',
    text: '<p>We work on 100% freelance basis so you are your own boss and you decide when and how much to work. However, keep in mind your account can be active only if you log in to the platform and complete at least one order once in 2 month.</p><p>At the same time, we recommend you to log in to your personal account at least once a day, especially if you have orders in progress (this way you can make sure you don\'t miss any important details or updates in the order).</p>',
  },
  'Can I snooze my account for some time?': {
    title: 'Can I snooze my account for some time?',
    category: 'settingsAndAvailability',
    text: 'Yes, you can. It is sad to know you cannot work for some time but we will be happy to welcome you back! Please contact your personal Care Manager via the Issue Resolution Centre to discuss the conditions of future cooperation.”',
  },
  'How bonuses work?': {
    title: 'How bonuses work?',
    category: 'bonuses',
    text: 'Bonus is a financial reward for productive work on our platform. Typically bonuses are dedicated to the number of orders or pages you have to complete before a specific deadline. The goals and compensation rates depend on your current activity and overall performance on the platform. Keep in mind that the status of the orders has to be “Completed” before the deadline for orders/pages to be counted in to a certain bonus program.',
  },
  'What is “Loyalty program”?': {
    title: 'What is “Loyalty program”?',
    category: 'bonuses',
    text: '<p>Loyalty program is a permanent initiative that allows you to earn <b>up to $30 of bonus monthly.</b></p><p>Consistency is the primary value in Loyalty program - if you fail to complete 50 pages in one month, the program will be reset and you will have to start from the very beginning (1st month).</p><p><b>In order to be eligible you have to complete 50+ pages monthly for a certain period of time:</b></p><ul><li>Complete 50+ pages within the 1st month ( bonus is not yet added yet, but bonus tasks for the next months are activated)</li><li>Complete 50+ pages during the 2nd month and get a $15 bonus!</li><li>Complete 50+ pages during the 3rd month and get a $20 bonus!</li><li>Complete 50+ pages during the 4th and following months and be sure to receive a $30 bonus on a monthly basis.</li></ul>',
  },
  'What is “Productivity bonus”?': {
    title: 'What is “Productivity bonus”?',
    category: 'bonuses',
    text: 'Productivity bonus is a seasonal offer that allows you to earn extra money for your work. It is short-term bonus that is awarded for completion of a certain number of orders (or pages) during the stated period. The target for the bonus is defined by your regular performance. You will be informed about available Productivity bonuses via email. The details of Productivity bonuses will be available in “My Progress” tab in your account.',
  },
  'I have not received my bonus. What should I do?': {
    title: 'I have not received my bonus. What should I do?',
    category: 'bonuses',
    text: 'The bonus might not be added in case the conditions for the reward have not been met (meaning the number of pages or orders is not sufficient for the bonus). <br/><br/>If you have checked the orders and you are sure you still deserve the bonus, feel free to contact your Care Manager via the Issue Resolution Centre for further clarifications.',
  },
  'What is “Pending balance”?': {
    title: 'What is “Pending balance”?',
    category: 'finance',
    text: '<p>Pending balance reflects the gross amount of all “Pending transactions”. Once the order is completed, a transaction will be created in “Pending” status. It means you cannot withdraw the money straight to your payment method until the money is available. For A+, A, B and C level writers, the transaction is pending for 10 days after the order is completed. For the rest groups, the transaction is “Pending” for 14 days after the order is completed.</p><p>We cannot pay the money for the order right away because during this time the client is likely to come back with some corrections or requests and you might have to complete the work on the order before you withdraw the money.</p>',
  },
  // 'Why do I need to upload my documents?': {
  //   title: 'Why do I need to upload my documents?',
  //   category: 'finance',
  //   text: '<p>We use documents for identity verification to make sure the payments are processed properly. Uploading documents is obligatory for Skrill verification only. If you use Payoneer, it is not necessary for you to provide any documents.</p><p>Please note we might ask you to provide you personal documents in extraordinary cases to verify your identity.</p>',
  // },
  'I cannot request a withdrawal, it says "Setup your payment details”. What should I do?': {
    title: 'I cannot request a withdrawal, it says "Setup your payment details”. What should I do?',
    category: 'finance',
    text: '<p>Please check your Livingston Research account settings and make sure that your selected Payment method was verified.</p><p>Then, please check the “Primary payment method” field - it has to be filled out with the Payment method you intend to use (keep in mind you have to do it no later than 5 days before the withdrawal due date).</p>',
  },
  'Why can\'t I set a new payment method? It is said that this option is temporarily unavailable.': {
    title: 'Why can\'t I set a new payment method? It is said that this option is temporarily unavailable.',
    category: 'finance',
    text: 'Please note that you cannot set up a new payment method 5 days before the withdrawal date. It means that this option is not available from 5th till 10th each month as well as from 20th till 25th.',
  },
  'I have added my Payoneer ID, but cannot verify my Payoneer account. What should I do?': {
    title: 'I have added my Payoneer ID, but cannot verify my Payoneer account. What should I do?',
    category: 'finance',
    text: 'Please make sure you have entered correct Payoneer ID (which is a Payee ID that can be taken from your Payoneer account under Activity>Funding sources).<br/><br/>Once you have entered your Payee ID, the verification will be conducted by your Care Manager within 1-2 business days.',
  },
  'Is it obligatory to have 2 verified payment methods to get my money?': {
    title: 'Is it obligatory to have 2 verified payment methods to get my money?',
    category: 'finance',
    text: 'It is not obligatory to have 2 payment methods verified to request a withdrawal. You are to have at least one option verified. We recommend having 2 methods for your financial security. If something goes wrong with one, you will have another one available as a back-up plan.',
  },
  'When will my salary be paid?': {
    title: 'When will my salary be paid?',
    category: 'finance',
    text: 'You are able to request your payments twice a month – by the 10th of each month and by the 25th of each month. That means that the latest time you can possibly request the payment is 23:59 on 9th and 23:59 on 24th.',
  },
  'I have requested my money once and now I want request money again. What will happen to my previous withdrawal?': {
    title: 'I have requested my money once and now I want request money again. What will happen to my previous withdrawal?',
    category: 'finance',
    text: 'You can request multiple withdrawals within one pay period meaning you can request money as often as you like but on the 10th and the 25th of each month only the last transaction will be processed. In this case old transaction is canceled and the most recent withdrawal includes the amount from the previous one.',
  },
  'I withdrew the money but it is not in my account yet.': {
    title: 'I withdrew the money but it is not in my account yet.',
    category: 'finance',
    text: '<p>All requested payments will be processed within 10 days after the withdrawal deadline.</p><p>For example, if you requested your payment on 9th, you will receive it by 20th of the same month. However, if you requested your payment on 10th, 1 a.m., it will be processed by the 5th of the following month, that is, in the next payment period.</p>',
  },
  'How can I delete my account?': {
    title: 'How can I delete my account?',
    category: 'terminationOfCooperation',
    text: 'You have a right to terminate cooperation with us anytime by notifying your personal Care Manager. In such case you have to make sure there are no orders in progress in your account, complete any possible revisions and withdraw the money from the account.',
  },
  'Why my account can be deleted?': {
    title: 'Why my account can be deleted?',
    category: 'terminationOfCooperation',
    text: '<p>The Company reserves a right to terminate cooperation and delete Freelancer\'s account (by notifying the Freelancer via email or phone) in the following cases:</p><ul><li>Disclosure of information considering orders, selling/donating/sharing papers with any third parties.</li><li>Using several user names or fake credentials.</li><li>Systematic violation of Freelancer\'s responsibilities.</li><li>Constant quality issues.</li><li>Quality issues present on early stages of cooperation</li></ul><p>Additionally, Freelancer’s account can get frozen after inactivity (no logins and/or completed orders) on the platform in over 90 days. Freelancer has a right to submit a request for re-activating the account at any moment, which will be reviewed by Freelancer Care team.</p>',
  },
  'Which orders can I accept?': {
    title: 'Which orders can I accept?',
    category: 'acceptingOrders',
    text: '<p>You can accept any order available in “Find orders” tab in your personal account. Order availability is determined by your account status and the group you belong to.</p><p>You can choose the orders depending on your field of expertise, price and deadline.</p>',
  },
  'How much will I get for the order?': {
    title: 'How much will I get for the order?',
    category: 'acceptingOrders',
    text: 'The price you can see in the order is the final amount you will get when the order is completed.',
  },
  'What does “reserve order” mean?': {
    title: 'What does “reserve order” mean?',
    category: 'acceptingOrders',
    text: 'You can reserve the order for 7 minutes before accepting it to check the instructions carefully and make sure you can complete it. When you reserve the order, it is not visible to other writers so you can make sure nobody will accept it while you are checking the guidelines. Rejecting a reserved order will not lead to any consequences for you.',
  },
  'How is deadline in the order calculated?': {
    title: 'How is deadline in the order calculated?',
    category: 'acceptingOrders',
    text: 'The deadline in the order is the time when the final paper has to be uploaded to the platform. The deadline is shown according to the timezone of your IP meaning your local time. In the order itself below the deadline you can see how much time there is left for work. We strongly recommend you to upload the final paper before the deadline to save time for possible corrections or improvements.',
  },
  'What to do if I like the order but the instructions are not full?': {
    title: 'What to do if I like the order but the instructions are not full?',
    category: 'acceptingOrders',
    text: 'We recommend you not to accept the order if you are not 100% sure you can complete it. If the order you like seems to lack some files or instructions, report them with the help of the button “Report the issue” in the upper right corner of the order. Our Support team will contact the client to clarify the details and you will have a chance to accept the order with complete instructions.',
  },
  'What if I cannot find suitable orders?': {
    title: 'What if I cannot find suitable orders?',
    category: 'acceptingOrders',
    text: 'When the order is placed on the platform, it is visible to numerous freelancers and thus the best orders are accepted really quick. If you would like to find something suitable, you have to log in to the platform at least a couple of times a day. The best time to check for orders would be late evening and night time as per Eastern Daylight Time since  most of our clients are from the US and Canada.',
  },
  'I accepted an order but it disappeared. Why?': {
    title: 'I accepted an order but it disappeared. Why?',
    category: 'acceptingOrders',
    text: 'That doesn\'t happen too often but sometimes Support Team might reject you from the order because it is reserved for a different writer. In this case you should receive an email with detailed explanations for the decision. Otherwise, feel free to contact them asking for clarifications.',
  },
  'How can I cancel the order I accepted?': {
    title: 'How can I cancel the order I accepted?',
    category: 'acceptingOrders',
    text: 'Once you accept the order, you confirm being able to complete it according to the instructions provided within the stated deadline. Refusal to complete the order will result in a 100% fine and a warning. If you are ready to face the consequences, please inform the Support team and they will reject you from the order.',
  },
  'What is a Recommended order?': {
    title: 'What is a Recommended order?',
    category: 'typesOfOrders',
    text: 'Recommended orders are selected specifically for you based on your field of expertise, knowledge and experience. If the Support team recommends an order to you, it means you are literally chosen to take care of it. Order recommendation normally leads to a significant price increase so we highly recommend you to pay more attention to such orders. You will be notified about order recommendation via email and sms so do not miss the opportunity to accept it and check it out ASAP.',
  },
  'What is a Suggested order?': {
    title: 'What is a Suggested order?',
    category: 'typesOfOrders',
    text: 'Orders can be suggested to you by the Support team and your Personal Success Manager. Such orders are chosen for you based on your preferences and order availability. When any order is suggested, you will receive a notification in your inbox so it is advisory to check the platform as soon as you can.',
  },
  'Why some orders are measured in time?': {
    title: 'Why some orders are measured in time?',
    category: 'typesOfOrders',
    text: 'When the client needs help with an assignment, that cannot be measured in words or pages, we estimate how much time it would take and charge the client accordingly. Such orders normally include math problems, calculations, work with graphs, charts etc. By accepting an order, measured in time, you confirm being able to complete the task required within the provided time. In case such an order involves writing (as graph and comment), keep in mind that 60 minutes equals 300 words.',
  },
  'Some orders are connected to the other ones. What does it mean and what should I do?': {
    title: 'Some orders are connected to the other ones. What does it mean and what should I do?',
    category: 'typesOfOrders',
    text: 'Clients can order different parts of one task separately in order to see the progress of the writer or break up the payment. In this case we have to make sure different orders are coherent and thus we will provide you with all the necessary information about the previous order and the previous paper as well.  All you have to do is study the provided materials and complete your order according to instructions (pretty much the same thing you would do with any other order).',
  },
  'Why some orders have complex instructions?': {
    title: 'Why some orders have complex instructions?',
    category: 'typesOfOrders',
    text: '<p>Sometimes clients request complex project that involve more than one type of task for example an research paper and a presentation, a literature review and a research proposal, a lab report and an essay. In such case we want to be precise with what exactly should be done. <br/>The order instructions will be separated into logical batches of information. On the top of the details you will see a short summary of all the parts of the order, the detailed instructions will be given below. <br/>The length of the order shows multiple measurements (eg 3 pages/120 minutes) but the price of the order is the total you will get after the order is completed.</p>',
  },
  'What is so particular about discussion post assignments?': {
    title: 'What is so particular about discussion post assignments?',
    category: 'typesOfOrders',
    text: '<p>Discussion post is an entry to a discussion board. Discussion board is an online forum where students carry on a discussion on a topic suggested by their professor.</p><p>The first post is always published by the professor and contains the topic and specific instructions for the students. <br/>Students have to reply to the professor`s post following his instructions. <br/>Depending on the task, students may be required to respond to each other`s posts.</p><p>This task is a part of a course so the structure, style and requirements will vary but the text should be coherent and cohesive. Do not forget to write a thesis statement.</p><p>There are 2 main types of a discussion post:</p><p>1. Personal reflection. <br/> Such assignments will ask for your personal opinion on the course or posts of the group-mates. In this case in-text citations or references are not required and personal pronouns can be used.</p><p>2. Contribution to a particular topic. <br/> In this case outside reliable sources should be cited in text and in the reference page. Such posts require academic style of writing so you have to avoid personal pronouns or anything informal. </p>',
  },
  'Why some orders are named “DO NOT ACCEPT”?': {
    title: 'Why some orders are named “DO NOT ACCEPT”?',
    category: 'typesOfOrders',
    text: 'In order to ensure great writing experience for you, we regularly check how our system works and test all new features. For this purpose we create “test” orders and name them appropriately. If you see an order that says “DO NOT ACCEPT” it means our IT team is checking something on the platform and if you accept it, it will break the “experiment”. Please do not accept such orders.',
  },
  'What if order instructions are not full or not clear enough?': {
    title: 'What if order instructions are not full or not clear enough?',
    category: 'orderInWork',
    text: 'If you accept an order and you have any questions regarding the order instructions, missing files or details, you have to contact the Support team as soon as possible via message box in the order itself or issue resolution center. If you inform us about insufficient instructions too late, we might not be able to help you out. It is your responsibility to notify the Support Team about missing details in the order. Otherwise, you will be responsible for a failure to meet the initial instructions.',
  },
  'What to do if I need more time?': {
    title: 'What to do if I need more time?',
    category: 'orderInWork',
    text: '<p>If you need more time to complete the order, you can request an extension from the order itself. Once you accept the order, you can see a button “Request an extension” on the upper right side of the order form, right under the price of the order.</p><p>Please be aware that any extension leads to the price drop. The price will change depending on the length of extension and the urgency of the order.</p>',
  },
  'Why the status of the order does not change to “Completed” when I upload my final paper?': {
    title: 'Why the status of the order does not change to “Completed” when I upload my final paper?',
    category: 'orderInWork',
    text: 'The status of the order automatically changes to Completed as per initial deadline and the compensation is transferred to Pending balance. If you upload the final paper before your actual deadline, it will have "In Work" status until your deadline comes.',
  },
  'What is pre-checking?': {
    title: 'What is pre-checking?',
    category: 'orderInWork',
    text: '<p>Pre-checking means the paper is checked and fixed before being sent to client. Not all of your papers will be pre-checked, our QA managers will review your orders randomly. If any issues are spotted in the paper, we will have to apply a fine. The amount of a fine will be defined by the quality of the paper.</p><p>QA managers might fix easy issues themselves or ask you to revise the paper (it might lead to 20% fine). If the issues cannot be easily fixed, you might have to revise the paper yourself (it might lead to 20-100% fine). Depending on the deadline and the complexity of revision, revision might be completed by a different writer.</p>',
  },
  'What is a revision?': {
    title: 'What is a revision?',
    category: 'revision',
    text: 'Revision means you have to fix the paper after completion. Normally revisions will concern minor issues like language and formatting but sometimes you will have to make major adjustments (when the initial instructions haven`t been followed). In case your client requests revision that goes beyond the initial instructions, we can offer you a bonus for revision completion.',
  },
  'Why do I have to revise the paper if the comments are not fair?': {
    title: 'Why do I have to revise the paper if the comments are not fair?',
    category: 'revision',
    text: 'According to our rules, revisions can concern corrections only within initial order instructions. Note that revision comments can include adjustments to personal writing style of the client or insignificant shift in the focus of research. If the revision comments seem to be unfair or irrelevant, you should message the Support team or report it via issue resolution center immediately. Be sure to provide detailed and accurate explanations why the revision request is unfair and wait for their decision.',
  },
  'What should I do if the plagiarism is detected once the final paper is submitted?': {
    title: 'What should I do if the plagiarism is detected once the final paper is submitted?',
    category: 'plagiarism',
    text: '<p>Each and every file you upload to the final paper section is automatically checked for plagiarism. If the plagiarism is detected, you will be able to download the plagiarism report and fix the paper accordingly.While working on the paper, please rewrite the highlighted parts and cite the information correctly. Afterward, re-upload the final paper.</p><p>If you believe the plagiarism report is irrelevant,  do not hesitate to contact our Support team immediately. If everything is alright, they will override the report. Plag report can be irrelevant only if the paper is in question-answer format or the initial instructions required excessive use of quotations.</p>',
  },
  'What is freelancer’s group/level?': {
    title: 'What is freelancer’s group/level?',
    category: 'qualityControl',
    text: 'Freelancer\'s group is a set of characteristics of your account on the platform. Your group defines the orders you can see on the platform, available bonuses and limitations. The group you belong to depends the average client\'s score, which is calculated based on your last 30 scores, but not less than 5. Detailed explanation of all groups` peculiarities can be found in the Freelancer\'s Guide.',
  },
  'What is a warning?': {
    title: 'What is a warning?',
    category: 'qualityControl',
    text: '<p>Warning is a system notification about any action that violates our rules on the platform. Every time there is any undesirable activity from your side, a warning is automatically generated.</p><p>The warnings influence the benefits you can enjoy in your current group.  The warnings are active for 10 orders after the undesirable action took place. In the account you will see the number of orders you have to complete for the warning to be deactivated.</p>',
  },
  'What types of warnings are there?': {
    title: 'What types of warnings are there?',
    category: 'qualityControl',
    text: '<p>Order/Revision deadline is missed -  Every time you submit an order after the deadline, this warning is generated. Note that this kind of warning is more serious than the Extension request and will move you to a lower group faster.</p><p>Rejected Revision - Every time you reject a revision request, this kind of warning is generated. It will not be generated in case you reject a revision request with a deadline less than 10 hours or in case 30 days have passed since the last paper was sent to client.</p><p>Plagiarism - Every time you submit an order with high level of plagiarism - this kind of warning is generated.</p><p>Retraction from order -  In case you refuse to complete an order that you\'ve taken or support team rejects you from your order due to the fact that you did not confirm your deadline and was not responsive to their calls.</p><p>Low QA score - Every time your order is scored 3.4 or lower (D or F grades), this type of warning is generated.</p><p>Order not submitted - Every time you fail to submit your order after the deadline this type of warning is generated. Order is considered to be not submitted both when you let the operators know that you won\'t submit it and there is no final paper after the deadline and you are not responsive. This warning is much more serious than DDL missed and will move you to a lower group faster.</p><p>Other (e.g. disciplinary issues) - This warning is generated manually by Support team and should always have a comment with a reason. In case you don\'t find any explanation or you do not agree with this warning, please contact you Success manager.</p>',
  },
  'What is a fine?': {
    title: 'What is a fine?',
    category: 'qualityControl',
    text: 'Fine is a balance deduction that is possible when some undesirable action takes place on the account. Fines are applied only to draw your attention to importance of our rules, policies or quality standards.The amount of fine depends on the violation. Fines are automatically applied to your “Available” balance without any delay.',
  },
  'What fines are there?': {
    title: 'What fines are there?',
    category: 'qualityControl',
    text: '<p>Fines can be applied for lateness, quality of the paper provided or violation of our policies.</p><p>Fines for lateness vary from 25% to 100% depending on lateness.<br />Please keep in mind that you will be rejected from the order if you miss the deadline for the order and do not inform the support team about possible delays. Do your best to answer our messages and calls in order to avoid fines.</p><p>Fines for quality issues vary from 5 to 100% depending on the situation.<br />If the QA managers spot some easily fixable issues, they will apply 5-20% fine.<br />If the score for the paper is 3-3.4, you will get 50% fine.<br />If the QA managers score the paper 2.9 and less, you will get 100% fine.<br />Failure to complete or revise the paper will lead to 100% fine.</p><p>We can apply a 100% fine of your balance as a penalty for fraudulent activity.</p>',
  },
  'Order related issues': {
    title: 'Order related issues',
    category: 'orderRelatedIssues',
    text: '<p>Any order related issues should be discussed with the Support team.<br />Support team will help you with any clarifications with the client, unclear order instructions, unfair revision requests and general questions related to academic writing.</p><span class="indent">Support team works 24/7</span><br /><span class="indent">Email: <a href="mailto:support@liv-research.com">support@liv-research.com</a></span>',
  },
  'Job related': {
    title: 'Job related',
    category: 'jobRelatedIssues',
    text: '<p>Any platform and account related issues should be discussed with your personal Care Manager. Care Team will help you with account settings, order availability, freelancer groups, warnings, bonuses, payment systems, withdrawals, and any other questions of a general character.</p><span class="indent">Care Team works business hours GMT +2 Monday to Friday</span><br /><span class="indent">Email: <a href="mailto:freelancer.care@liv-research.com">freelancer.care@liv-research.com</a></span>',
  },
  'What is a Tutoring order?': {
    title: 'What is a Tutoring order?',
    category: 'tutoringOrders',
    text: '<p>Tutoring order is the type of order where the client can request a live session in order instructions or while the order is in work. The help format is decided by the client, or offered by you if the client is not sure.</p><p>Tutoring orders can be found on the Find orders tab just as regular orders. They start with an NT index.</p><p>You can chat with the client directly, increase order size by sending estimates, and even schedule and hold live sessions in Tutoring orders.</p>',
  },
  'What is the difference between “Join chat” and “Accept”?': {
    title: 'What is the difference between “Join chat” and “Accept”?',
    category: 'tutoringOrders',
    text: '<p>Chat with client option allows you to clarify order instructions and reject the order with no fines applied if you cannot help. Ask your questions right after starting the chat while the client is online to get answers faster.</p><p>After you have all the answers you needed, you should either accept or reject the order.</p><p>Once you accept the order, you confirm being able to complete it according to the instructions provided within the stated deadline. Refusal to complete the order will result in a 100% fine and a warning.</p>',
  },
  'What does Preferred help format mean?': {
    title: 'What does Preferred help format mean?',
    category: 'tutoringOrders',
    text: '<p>The preferred help format is chosen by the client as the format of help they would like to get with a particular task: tutoring session, written explanation, step by step solution, exam preparation, file or other. If the client isn’t sure, it’s up to your to choose the best format and offer it to the client.</p><p>You will need to chat with the client and clarify more details. Don’t worry, there will be guidance for your next steps in each case.</p>',
  },
  'What to do if I like the order but the instructions are not full?': {
    title: 'What to do if I like the order but the instructions are not full?',
    category: 'tutoringOrders',
    text: '<p>If the order is available, click “Join chat” and ask questions to determine if you can help.</p><p>If you have already accepted the order, make sure to clarify everything you need to complete the order within the given deadline. You have a client chat for that, where you can communicate with the client directly.</p>',
  },
  'What to do if I need more time?': {
    title: 'What to do if I need more time?',
    category: 'tutoringOrders',
    text: '<p>If the deadline is too small, but you can help with the order, discuss it directly with the client. After you reach an agreement, inform the Support team via Messages so that they could update the order deadline.</p>',
  },
  'What to do if the client needs more help than order size allows?': {
    title: 'What to do if the client needs more help than order size allows?',
    category: 'tutoringOrders',
    text: '<p>In that case, you can create a new estimate. Fill in all the required fields, adjust the comment if needed and send it to the client.</p><p>You’ll get a chat notification, whether your estimate is accepted, rejected, or expired.</p><p>If the client declines it, you can negotiate how much work is needed based on the client’s response and send a new estimate.</p>',
  },
  'How can I schedule a session with the client?': {
    title: 'How can I schedule a session with the client?',
    category: 'tutoringOrders',
    text: '<p>The first step is to agree on the time that fits both you and the client. Then click “Schedule a tutoring session”. Fill in all the fields, including a Google meet link, and Save the session.</p><p> You can also edit and delete all created sessions in the Tutoring Sessions tab.</p><p>When the time for the scheduled session comes up, you will have a link to join it directly from the client chat.</p>',
  },
  'How can I complete the order?': {
    title: 'How can I complete the order?',
    category: 'tutoringOrders',
    text: '<p>If it is an order where written help was provided, upload the final file in client chat and click Submit order.</p><p>If it is an order where the client needed help via live session, just click Submit after you are done with the session(s).</p>',
  },
  'What fines are there for Tutoring orders?': {
    title: 'What fines are there for Tutoring orders?',
    category: 'tutoringOrders',
    text: '<p>Apart from the usual fines for lateness, quality and violation of policies, there are also fines for rejecting orders in progress, as well as for sharing personal information, prices with the client or harassing the client in the chat.</p><p>Rejecting order in work, as well as sharing prices with the client, will result in 100% fine.</p><p>Sharing your personal information (email, phone number, etc) and the company’s name with the client will result in 100% fine as well as potential removal of the Tutoring orders stream.</p><p>Should the client share contact information or prices first, the issue should be reported to the Support team immediately.</p><p>Being incivil to the client, using curse words or any other bullying will result in 100% fine as well as rejection from the order.</p>',
  },
};

const guideList = {
  // 'Terms and Conditions & Privacy Policy': {
  //     title: 'Terms and Conditions & Privacy Policy',
  //     category: 'general',
  //     url: 'https://modrcxviikco.s3.amazonaws.com/000/WritersAgreement2017.pdf',
  //     icon: 'pdf-icon'
  // },
  'Livingston Research Guide': {
    title: ' Livingston Research Guide',
    category: 'general',
    url: 'https://drive.google.com/file/d/1Y6LS-Bf5bnpoi8qRssJ3v3AxDknbXACp/view?usp=sharing',
    icon: 'pdf-icon',
  },
  'Scoring Rubric': {
    title: 'Scoring Rubric',
    category: 'general',
    url: 'https://drive.google.com/file/d/1CKhVACGxGjrn67NzzZ8tnzOFDFKuOq94/view',
    icon: 'pdf-icon',
  },
  'Browser Notifications Guide': {
    title: 'Browser Notifications Guide',
    category: 'general',
    url: 'https://drive.google.com/file/d/1ROovIrlKDfUrUTbxmQZ9UDrsIJfhuioh/view?usp=share_link',
    icon: 'pdf-icon',
  },
  'Payment methods overview': {
    title: 'Payment methods overview',
    category: 'paymentmethods',
    url: 'https://drive.google.com/file/d/1RZuCcxOuiK7lSbEDYIzkET871aQ-ta00/view',
    icon: 'pdf-icon',
  },
  'Payoneer guide': {
    title: 'Payoneer guide',
    category: 'paymentmethods',
    url: 'https://drive.google.com/file/d/12DGq1As7PX92bpjvZIus2mc79Mc31DMV/view',
    icon: 'pdf-icon',
  },
  'Wise guide': {
    title: 'Wise guide',
    category: 'paymentmethods',
    url: 'https://drive.google.com/file/d/1hChjok7PXADnjig4bO6wZVpMoWIHC7Qg/view?usp=drive_link',
    icon: 'pdf-icon',
  },
  'Writing a research paper': {
    title: 'Writing a research paper',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/WRITING+A+RESEARCH+PAPER.docx',
    icon: 'docx-icon',
  },
  '10 common mistakes': {
    title: '10 common mistakes',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/10+Common+Mistakes.docx',
    icon: 'docx-icon',
  },
  'Guide to academic writing': {
    title: 'Guide to academic writing',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Guide+to+Academic+Writing.docx',
    icon: 'docx-icon',
  },
  'Training on Thesis statement': {
    title: 'Training on Thesis statement',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Training+on+Thesis+statement.docx',
    icon: 'docx-icon',
  },
  'Training on Structure': {
    title: 'Training on Structure',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Training+on+Structure.pptx',
    icon: 'pptx-icon',
  },
  'Training on Sources Referencing': {
    title: 'Training on Sources Referencing',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Training+on+Sources+Referencing.pptx',
    icon: 'pptx-icon',
  },
  'Training on Research methods': {
    title: 'Training on Research methods',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Training+on+Research_methods.pdf',
    icon: 'pdf-icon',
  },
  'Databases & citing': {
    title: 'Databases & citing',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Databases+%26+citing.pdf',
    icon: 'pdf-icon',
  },
  'PowerPoint training visual': {
    title: 'PowerPoint training visual',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/PowerPoint+training+visual.pdf',
    icon: 'pdf-icon',
  },
  'Training on Phrasing and tenses': {
    title: 'Training on Phrasing and tenses',
    category: 'academicwriting',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Training+on+Phrasing_and_tenses_.pptx',
    icon: 'pptx-icon',
  },
  'APA template': {
    title: 'APA template',
    category: 'formattingstyles',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/APA+template.docx',
    icon: 'docx-icon',
  },
  'Chicago template': {
    title: 'Chicago template',
    category: 'formattingstyles',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Chicago+template.docx',
    icon: 'docx-icon',
  },
  'Harvard template': {
    title: 'Harvard template',
    category: 'formattingstyles',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Harvard+template.docx',
    icon: 'docx-icon',
  },
  'MLA template': {
    title: 'MLA template',
    category: 'formattingstyles',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/MLA+template.docx',
    icon: 'docx-icon',
  },
  'Bad example 1 (slides)': {
    title: 'Bad example 1 (slides)',
    category: 'badorders',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/bad_example_1_(slides).pptx',
    icon: 'pptx-icon',
  },
  'Bad example 2 (slides)': {
    title: 'Bad example 2 (slides)',
    category: 'badorders',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/bad_example_2_(slides).pptx',
    icon: 'pptx-icon',
  },
  'Bad example 3': {
    title: 'Bad example 3',
    category: 'badorders',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/bad_example_3.docx',
    icon: 'docx-icon',
  },
  'Bad example 4': {
    title: 'Bad example 4',
    category: 'badorders',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/bad_example_4.docx',
    icon: 'docx-icon',
  },
  'Good example 1': {
    title: 'Good example 1',
    category: 'goodorders',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Good_example_1.docx',
    icon: 'docx-icon',
  },
  'Good example 2': {
    title: 'Good example 2',
    category: 'goodorders',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Good_example_2.docx',
    icon: 'docx-icon',
  },
  'Good example 3': {
    title: 'Good example 3',
    category: 'goodorders',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Good_example_3.docx',
    icon: 'docx-icon',
  },
  'Good example 4': {
    title: 'Good example 4',
    category: 'goodorders',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Good_example_4.docx',
    icon: 'docx-icon',
  },
  'Good example 5 (slides)': {
    title: 'Good example 5',
    category: 'goodorders',
    url: 'https://modrcxviikco.s3.amazonaws.com/000/Good_example_5_(slides).ppt',
    icon: 'pptx-icon',
  },
};

export default {
  faqCats,
  faqFlatCats,
  guidesCats,
  paymentMethodsCategory,
  faqList,
  guideList,
};
