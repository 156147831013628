import React from 'react';

import Icon from 'components/Icon';

import classNames from 'utils/classNames';

import content from './content';

const InfoComponent = ({
  modalName, onNextClick, noBtn = false, btnText = 'Next',
  autoText = false, canClose = false,
  isBtnDisabled = false, customHtml = {}, customBtn = null,
  closeHandler,
}) => (
  <div className="info-modal">
    {canClose && <button className="btn btn-close info-modal__close_icon" type="button" onClick={closeHandler}><Icon className="svg-icon" iconName="close" /></button> }
    <div className="info-modal-container" dangerouslySetInnerHTML={{ __html: (customHtml || {}).html || content[modalName] }} />
    {!noBtn && <div className="ta-right"><button disabled={isBtnDisabled} onClick={onNextClick} className={classNames('btn btn-bright btn-next', { 'btn-70': !autoText })}>{btnText}</button></div>}
    {customBtn && <div className="ta-right">{React.createElement(customBtn.component, customBtn.props)}</div>}
  </div>
);

export default InfoComponent;

