import shortid from 'shortid';

const cropUnsafeSymbols = (string) => {
  const regexp = /[^0-9a-zA-Z!\-_.*'()\ ]/g;
  return string.replace(regexp, '').replace(/ +/g, ' ');
};

const recursiveDecodeURIComponent = (uriComponent) => {
  try {
    const decodedURIComponent = decodeURIComponent(uriComponent);
    if (decodedURIComponent === uriComponent) {
      return cropUnsafeSymbols(decodedURIComponent);
    }
    return recursiveDecodeURIComponent(decodedURIComponent);
  } catch (e) {
    return cropUnsafeSymbols(uriComponent);
  }
};

const createAmazonUpload = (number, file, onProgress, onComplete, onError) => {
  const host = 'https://g0yu0l4pxj.s3.amazonaws.com/';
  const fileId = `wt502fy0/${number}/${shortid.generate()}/${recursiveDecodeURIComponent(file.name)}`;
  const fileLocation = host + fileId;

  const formData = new FormData();
  formData.append('key', fileId);
  formData.append('AWSAccessKeyId', 'AKIAQ2ZTVN6TAR4CZ7CV');
  formData.append('acl', 'public-read');
  formData.append('policy', 'CnsgImV4cGlyYXRpb24iOiAiMjAzMy0xMi0wMVQxMjowMDowMC4wMDBaIiwKICAiY29uZGl0aW9ucyI6IFsKICAgIHsiYnVja2V0IjogImcweXUwbDRweGoiIH0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRrZXkiLCAid3Q1MDJmeTAvIl0sCiAgICB7ImFjbCI6ICJwdWJsaWMtcmVhZCIgfQogIF0KIH0K');
  formData.append('signature', 'wNkbL+0UdLoq0/IGlTcdJK4vqGw=');
  formData.append('file', file);
  const xhr = new XMLHttpRequest();
  xhr.open('POST', host);
  xhr.upload.addEventListener('progress', (e) => {
    if (e.lengthComputable) {
      const percent = Math.round(100 * e.loaded / e.total);
      onProgress(fileLocation, percent);
    }
  });
  xhr.addEventListener('load', () => {
    const headers = xhr.getAllResponseHeaders().toLowerCase();
    const etagValues = (headers || '').split('etag: ');
    let etag = '';
    if (etagValues[1]) {
      etag = etagValues[1].replace(/["']/g, '').replace(/[\r]/g, '').replace(/[\n]/g, '');
    }
    onComplete(fileLocation, etag);
  });
  xhr.addEventListener('error', () => onError(fileLocation));
  xhr.send(formData);


  return {
    location: fileLocation,
    abort: () => xhr.abort(),
  };
};

export default createAmazonUpload;
